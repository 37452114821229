import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from 'components/layout';
import Cursor from 'components/cursor';
import PostSlideshow from 'components/postSlideshow';
import Footer from 'components/footer';
import AboutInfo from 'components/aboutInfo';
import gsap from 'gsap';
import { Link } from 'gatsby';
import TriangleWhite from 'images/triangleWhite.svg';
import styled from 'styled-components';

const AboutContainer = styled.div`
  padding-top: 15vh;

  .founders {
    padding-top: 1.5rem;
    width: calc(100% / 6);
    transform: translateX(400%);

    p {
      margin-bottom: 0;
    }

    .foundersNames {
      font-weight: 600;
    }
  }

  .aboutSlideshow {
    padding-bottom: 10vh;
  }

  @media (max-width: 950px) {
    .founders {
      width: calc(100% / 3);
      transform: translateX(200%);
    }
  }

  @media (max-width: 450px) {
    .founders {
      width: calc(100% * 2 / 3);
      transform: translateX(50%);
    }
  }
`;

const About = ({ data, location }) => {
  const gallery = data.contentfulAbout.gallery;

  let scroll;

  const handleScroll = (destination, offset, time) => {
    scroll.scrollTo(destination, offset, time);
  };

  useEffect(() => {
    gsap.to('#linesContainer', 3, { scaleY: 1 });

    gsap.to(
      '.hamburgerMenuContainer, .logoContainer, .icons, .triangleContainer, .heroTimerContainer',
      1,
      {
        autoAlpha: 1,
      }
    );

    import('locomotive-scroll').then(locomotiveModule => {
      scroll = new locomotiveModule.default(
        {
          el: document.querySelector('[data-scroll-container]'),
          smooth: true,
          getSpeed: true,
          getDirection: true,
          useKeyboard: true,
          inertia: 0.7,
          smoothMobile: true,
          touchMultiplier: 2.5,
        },
        []
      );

      setTimeout(() => {
        scroll.update();
      }, 300);

      window.addEventListener('resize', scroll.update());
    });

    return () => {
      window.removeEventListener('resize', scroll.update());
      scroll.destroy();
    };
  }, []);

  return (
    <Layout data={data} location={location}>
      <Cursor />
      <AboutContainer data-scroll-container>
        <section data-scroll-section>
          <AboutInfo data={data} />
        </section>
        <section data-scroll-section className="aboutSlideshow">
          <PostSlideshow images={gallery} />
          <p className="founders">
            <span className="foundersText">Founders</span>{' '}
            <span className="foundersNames">Alex Ordanis, Chris Yurkovich</span>
          </p>
        </section>
        <Footer data={data.contentfulMotto} />
      </AboutContainer>
    </Layout>
  );
};

About.propTypes = {
  data: PropTypes.object.isRequired,
};

export const query = graphql`
  query AboutQuery {
    contentfulAbout {
      paragraph1 {
        childMarkdownRemark {
          html
        }
      }
      paragraph2 {
        childMarkdownRemark {
          html
        }
      }
      paragraph3 {
        childMarkdownRemark {
          html
        }
      }
      headline {
        childMarkdownRemark {
          html
        }
      }
      gallery {
        fluid(maxWidth: 1000) {
          ...GatsbyContentfulFluid_withWebp
        }
        title
        description
        file {
          url
        }
      }
      title
    }
    contentfulMotto {
      sideText1 {
        childMarkdownRemark {
          html
        }
      }
      sideText2 {
        childMarkdownRemark {
          html
        }
      }
      sideText3 {
        childMarkdownRemark {
          html
        }
      }
      motto {
        childMarkdownRemark {
          html
        }
        motto
      }
    }
    allContentfulTimeline(
      sort: { order: DESC, fields: date }
      filter: { node_locale: { eq: "en-US" } }
    ) {
      edges {
        node {
          id
          title
          slug
          date
        }
      }
    }
    allContentfulPost(filter: { node_locale: { eq: "en-US" } }) {
      edges {
        next {
          slug
        }
        previous {
          slug
        }
        node {
          id
          slug
          title
          year
          hero {
            file {
              url
            }
          }
          tag {
            title
          }
        }
      }
    }
  }
`;

About.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
};

export default About;
