import React, { useEffect } from 'react';
import ScrollingTitle from 'components/scrollingTitle';
import styled from 'styled-components';

const AboutInfoContainer = styled.div`
  .aboutContent {
    display: flex;
    padding: 10vh 0;
  }

  .awardsTitle {
    width: calc(100% / 6);
    transform: translateX(100%);
  }

  .aboutText {
    transform: translateX(100%);
  }

  .headline {
    width: calc(100% / 6);
  }

  .paragraphs {
    width: calc(100% / 2);
    display: flex;
  }

  .paragraph {
    width: calc(100% / 3);
  }

  .headline {
    p {
      font-size: 1.4rem;
      line-height: 1;
    }
  }

  .aboutText p {
    margin-bottom: 0;
  }

  @media (max-width: 950px) {
    .awardsTitle {
      transform: translateX(100%);
    }

    .headline {
      width: calc(100% / 3);
    }

    .aboutText {
      transform: translate(0);
    }

    .paragraphs {
      width: calc(100% * 2 / 3);
      flex-direction: column;
    }

    .paragraph {
      width: 100%;

      p {
        margin-bottom: 1.5rem;
      }
    }
  }

  @media (max-width: 550px) {
    .awardsTitle {
      width: calc(100% / 3);
      transform: translateX(0%);
    }
  }

  @media (max-width: 550px) {
    .aboutContent {
      flex-direction: column;
    }

    .headline {
      width: calc(100% * 2 / 3);

      p {
        margin-bottom: 2rem;
        padding: 0;
      }
    }

    .aboutText {
      transform: translateX(25%);
    }

    .paragraph p {
      padding: 0;
    }
  }
`;

const AboutInfo = ({ data }) => {
  const { headline, paragraph1, paragraph2, paragraph3 } = data.contentfulAbout;

  return (
    <AboutInfoContainer>
      <div className="awardsTitle">
        <ScrollingTitle title={data.contentfulAbout.title} />
      </div>
      <div className="aboutContent">
        <div
          className="headline aboutText"
          dangerouslySetInnerHTML={{
            __html: headline.childMarkdownRemark.html,
          }}
        ></div>
        <div className="paragraphs">
          <div
            className="paragraph1 paragraph aboutText"
            dangerouslySetInnerHTML={{
              __html: paragraph1.childMarkdownRemark.html,
            }}
          ></div>
          <div
            className="paragraph2 paragraph aboutText"
            dangerouslySetInnerHTML={{
              __html: paragraph2.childMarkdownRemark.html,
            }}
          ></div>
          <div
            className="paragraph3 paragraph aboutText"
            dangerouslySetInnerHTML={{
              __html: paragraph3.childMarkdownRemark.html,
            }}
          ></div>
        </div>
      </div>
    </AboutInfoContainer>
  );
};

export default AboutInfo;
